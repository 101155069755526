import './App.css';
import { ForceGraph2D } from "react-force-graph";
import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback
} from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitter, faTelegram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function App() {
  const fgRef = useRef();
  var count = 0;
  useEffect(() => {
    fgRef.current.d3Force("link").distance(120);
  });

  let data = {
    nodes: [
      {
        id: "id1",
        isClusterNode: true,
        name: "Trustless Labs",
        val: 333,
        color: "#21B6A8"
      },
      {
        id: "id2",
        name: "Web3",
        degrees: 30,
        val: 33,
        color: "#0E86D4"
      },
      {
        id: "id3",
        name: "DeFi",
        val: 33,
        color: "#0E86D4"
      },
      {
        id: "id4",
        name: "Metaverse",
        val: 33,
        color: "#0E86D4"
      },
      {
        id: "id5",
        name: "NFT",
        val: 33,
        color: "#0E86D4"
      },
      {
        id: "id6",
        name: "GameFi",
        val: 33,
       color: "#0E86D4"
      },
      {
        id: "id7",
        name: "DAO",
        val: 33,
       color: "#0E86D4"
      }
    ],
    links: [
      {
        source: "id1",
        target: "id2",
      },
      {
        source: "id1",
        target: "id3"
      },

      {
        source: "id1",
        target: "id6"
      },
      {
        source: "id1",
        target: "id4"
      },
      {
        source: "id1",
        target: "id5"
      },
      {
        source: "id1",
        target: "id7"
      },
    ]
  };

  return (
    <div className="App">
      {/* <div class='light x2'></div>
      <div class='light x3'></div>
      <div class='light x4'></div>
      <div class='light x5'></div>
      <div class='light x6'></div>
      <div class='light x7'></div>
      <div class='light x8'></div>
      <div class='light x9'></div> */}
      <ForceGraph2D
        ref={fgRef}
        graphData={data}
        cooldownTicks={500}
        linkDirectionalParticles={3}
        linkDirectionalParticleWidth={1}
        linkDirectionalParticleColor={() => 'white'}
        onNodeDragEnd={(node) => {
        }}
        nodeCanvasObjectMode={() => "after"}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const label = node.name;
          const fontSize = 6;

          if (node.isClusterNode) {
            node.fx = 0;
            node.fy = 0;
            ctx.font = `bold 21px Exo`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#fff"; //node.color;
            ctx.fillText("Trustless", node.x, node.y - 7);
            ctx.fillText("Labs", node.x, node.y + 13);
          }
          else {
            ctx.font = `bold 6px Exo`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#fff"; //node.color;
            ctx.fillText(label, node.x, node.y);
          }
        }}
      />

      <div className='logo-section'>
        <a href='https://trustlesslabs.xyz'><img src="/trustlesslabs-logo.png" alt="trustlesslabs.xyz"  width="50px"  /></a>
      </div>
      <div className='social-section'>
        <a href='mailto:info@trustlesslabs.xyz'><FontAwesomeIcon icon={faEnvelope} /></a>
        <FontAwesomeIcon icon={faTwitter} />
        <FontAwesomeIcon icon={faDiscord} />
        <FontAwesomeIcon icon={faTelegram} />
      </div>
  </div>
  );
}
export default App; 